<template>
  <v-container fluid class="py-0">
    <heading />
    <v-row class="mt-5">
      <v-col>
        <v-chip
          outlined
          :color="`${active == 'today' ? 'primary' : ''}`"
          class="text-lowercase cursor-pointer"
          @click="filter('today')"
        >
          {{ $t("today") }}
        </v-chip>
        <v-chip
          outlined
          :color="`${active == 'last-week' ? 'primary' : ''}`"
          class="ml-1 text-lowercase cursor-pointer"
          @click="filter('last-week')"
        >
          {{ $t("past-week") }}
        </v-chip>
        <v-chip
          outlined
          :color="`${active == 'this-month' ? 'primary' : ''}`"
          class="ml-1 text-lowercase cursor-pointer"
          @click="filter('this-month')"
        >
          {{ $t("this-month") }}
        </v-chip>
        <v-chip
          outlined
          :color="`${active == 'last-month' ? 'primary' : ''}`"
          class="ml-1 text-lowercase cursor-pointer"
          @click="filter('last-month')"
        >
          {{ $t("last-month") }}
        </v-chip>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col cols="12" class="big-chips">
        <big-chip
          type="success"
          :number="counters.paid"
          :description="$t('completed-payments')"
        />
        <big-chip
          type="warning"
          :number="counters.pending"
          :description="$t('pending-payments')"
        />
        <big-chip
          type="danger"
          :number="counters.error"
          :description="$t('payments-errors')"
          :action="{ name: 'pending-payments', query: { status: 'failed' } }"
        />
      </v-col>
    </v-row>
    <payments-table
      no-previous
      :filters="paymentFilters"
      @syncTotals="totalItems = $event"
    >
      <template #[`empty-search`]> &nbsp; </template>
    </payments-table>
  </v-container>
</template>

<script>
import BigChip from "./components/BigChip";
import Heading from "./components/Heading";
import PaymentsTable from "./Payments/Table";
import * as dayjs from "dayjs";
import payments from "../requests/payments";

export default {
  components: {
    Heading,
    BigChip,
    PaymentsTable,
  },
  props: {},
  mixins: [],
  computed: {
    paymentFilters: function () {
      return {
        from: this.params.from.format("YYYY-MM-DD"),
        to: this.params.to.format("YYYY-MM-DD"),
      };
    },
    now: function () {
      return dayjs().format("D MMM").toLowerCase();
    },
  },
  data: () => ({
    params: {
      from: null,
      to: null,
    },
    counters: {
      pending: null,
      paid: null,
      error: null,
    },
    active: "today",
    totalItems: 0,
  }),
  async created() {
    this.params.from = dayjs();
    this.params.to = dayjs();
  },
  watch: {
    params: {
      handler: async function () {
        this.counters = await payments.scheduleCounters(this.paymentFilters);
      },
      deep: true,
    },
  },
  methods: {
    filter: async function (type) {
      this.active = type;
      this.params.to = dayjs();
      if (type == "last-week") {
        this.params.from = dayjs().subtract(7, "day");
        return;
      }
      if (type == "this-month") {
        this.params.from = dayjs().startOf("month");
        this.params.to = dayjs().endOf("month");
        return;
      }
      if (type == "last-month") {
        this.params.from = dayjs().subtract(1, "month").startOf("month");
        this.params.to = dayjs().subtract(1, "month").endOf("month");
        return;
      }
      this.params.from = dayjs();
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  .big-chips {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;

    > div {
      flex: 1;
      min-width: 300px;
    }
  }
}
</style>
